.input-form-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.input-form {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: auto;
}

.input-text {
  width: calc(100% - 500px);
  padding: 0.25rem 0.5rem;
  font-size: 17px;
  background-color: #555;
  color: #fff;
  border: 2px solid #888;
  border-radius: 4px;
  margin-bottom: 1rem;
  resize: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow-y: auto;
  line-height: 35px;
  font-family: var(--font-family);
  ;
}

.send-button-wrapper {
  position: absolute;
  bottom: 84px;
  right: 250px;
}

.send-button {
  height: 18px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  cursor: pointer;
  border-radius: 4px;
  transition-duration: 0.4s;
}

.send-button:hover {
  background-color: rgba(97, 218, 251, 0.1);
  color: var(--primary-color);
}

.input-form-scroll {
  position: absolute;
  bottom: 85px;
  right: 55px;
}

.input-form-scroll button {
  background-color: #282c342b;
  border-radius: 50%;
  border-color: #282c34;
  border-width: 0px;
  color: #ffffff58;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  /* Add this line to make the text bold */
  width: 30px;
  height: 30px;
  text-align: center;
  padding-right: 5px;
  padding-bottom: 5px;
}

.input-form-scroll button:hover {
  background-color: #0b93f6;
}

.terms-link-wrapper {
  text-align: center;
  margin-top: 10px;  
}

.terms-link, .privacy-link {
  color: #888;
  text-decoration: none;
  font-size: 12px; /* Make the text smaller */
}

.terms-link-separator {
  font-size: 12px;
  color: #888;
}

.terms-link:hover, .privacy-link:hover {
  color: #555;
}

@media screen and (max-width: 768px) {
  .input-text {
    width: calc(100% - 60px);
  }

  .send-button-wrapper {
    right: 30px;
  }
}