.chat-container {
  background-color: #444;
  color: white;
  border-radius: 5px;
  padding: 1rem;
  height: calc(100% - 80px);
  overflow-y: auto;
  margin-bottom: 1rem;
  width: 96%;
  padding-top: 100px;
  padding-bottom: 130px;
}

@keyframes loadingDots {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dot {
  animation: loadingDots 1s linear infinite;
}

.dot-1 {
  animation-delay: 0s;
}

.dot-2 {
  animation-delay: 0.33s;
}

.dot-3 {
  animation-delay: 0.66s;
}
