:root {
  --primary-color: #61dafb;
  --background-color: #282c34;
  --text-color: white;
  --secondary-text-color: #aaa;
  --error-color: red;
  --font-family: Tahoma, Helvetica, sans-serif;
  --base-spacing: 1rem;
  --message-user-color: #0b93f6;
  --message-ai-color: #333;
  --input-bg-color: #f0f0f0;
  --body-bg-color: #e5e5ea;
}

html,
body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--body-bg-color);
}

.App {
  text-align: center;
  font-family: var(--font-family);
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--background-color);
  min-height: 100vh;
}

.App-header {
  background-color: var(--background-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color);
}

@media screen and (max-width: 768px) {
  .App-logo {
    height: 8rem;
  }

  .App-header {
    padding: 10px;
    /* Add padding around the header content */
  }
}