.message {
  display: block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 85%;
  margin-bottom: 10px;
  text-align: left;
  font-size: 14px;
  line-height: 1.4;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.message.user {
  background-color: var(--message-user-color);
  color: white;
  align-self: flex-end;
  margin-left: 10%;
  max-width: 100%;
  border-bottom-right-radius: 5px;
}

.message.ai {
  background-color: var(--message-ai-color);
  color: #eee;
  align-self: flex-start;
  margin-right: 10%;
  border-bottom-left-radius: 5px;
}

.message.error {
  color: var(--error-color);
}

.message.selected {
  background-color: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 768px) {
  .message.user {
    margin-left: 5%;
    max-width: 95%;
  }

  .message.ai {
    margin-right: 5%;
  }
}