.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(40, 44, 52, 0.8);
  padding: 10px;
  position: fixed;
  width: 100%;
  height: 50px;
  z-index: 1000;
}

.toolbar-logo {
  display: flex;
  align-items: center;
}

.toolbar-logo img {
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.toolbar-title a {
  color: #ffffff;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
}

.toolbar-buttons {
  display: flex;
  justify-content: center;
  flex: 1;
}

.toolbar-buttons button {
  background-color: #61dafb;
  border: none;
  border-radius: 4px;
  color: #282c34;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
  padding: 5px 10px;
}

.toolbar-buttons button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.toolbar-buttons button:hover {
  background-color: #0b93f6;
}

.api-key {
  display: flex;
  align-items: center;
}

.api-key-label {
  color: #ffffff;
  margin-right: 10px;
}

.api-key-input {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #282c34;
  font-size: 16px;
  margin-right: 20px;
  padding: 5px 10px;
}

.remaining-requests {
  background-color: rgba(0, 0, 0, 0.01);
  border-radius: 5px;
  padding: 3px;
  font-size: 12px;
  color: white;
  display: inline-block;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 20px;
  margin-bottom: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.remaining-requests.no-requests {
  background-color: #ff4c4c;
  color: #fff;
}

.user-box {
  margin-right: 20px;
}

.user-info {
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.user-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.user-name {
  color: white;
  font-size: large;
  margin: 2px;
}

.user-sub {
  color: white;
  font-size: medium;
}

.signIn-button {
  padding: 4px 12px;
  margin-right: 12px;
}

.signOut-button {
  background-color: #61dafb;
  border: none;
  border-radius: 20px;
  color: #282c34;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  padding: 4px 12px;
  transition: 0.3s;
}

.signOut-button:hover {
  background-color: #44c3eb;
  color: #fff;
}

.toolbar-buttons select {
  background-color: #61dafb88;
  border: none;
  border-radius: 4px;
  color: #282c34;
  font-size: 16px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}

.toolbar-buttons select:hover {
  background-color: #0b94f6d5;
}

.toolbar-buttons select:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  .toolbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    height: auto;
  }

  .toolbar-logo img {
    height: 30px;
  }

  .toolbar-title a {
    font-size: 20px;
  }

  .toolbar-buttons button, 
  .toolbar-buttons select,
  .signOut-button {
    font-size: 14px;
    padding: 10px 20px;
  }

  .toolbar-buttons,
  .user-box,
  .api-key {
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
  }

  .api-key-input,
  .toolbar-buttons button,
  .toolbar-buttons select,
  .signIn-button,
  .signOut-button {
    margin-bottom: 10px;
  }

  .user-image {
    width: 50px;
    height: 50px;
  }
}
